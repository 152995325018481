import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Box, Item, IconButton, Dropdown, Bubble, getTexts, formatRelativeDate, parseEmoji,
  keyPress, useTheme, colorShade, contrastColor,
} from '@twnel/web-components';
import IconLink from 'src/ui/util/components/IconLink';
import { useServiceTimeDate } from 'src/ui/util/connect/hooks';
import SearchBar from './SearchBar';

const HEIGHT = 60;

const onlineColor = (theme, online) => {
  if (online > 0) {
    return theme.success;
  }
  if (online < 0) {
    return colorShade(1, theme.background);
  }
  return theme.warning;
};

const Options = styled(Box)`
  padding: 4px 3px;
`;

const SingleOption = styled(Item)`
  display: flex;
  align-items: center;
  height: 1.875rem;
  line-height: 1.35rem;
  font-size: 0.98rem;
  font-weight: 500;
  color: ${({ theme }) => theme.actionable};
  padding: 0 0.9375rem 0 0.625rem;
  white-space: nowrap;
`;

const DoubleOption = styled(SingleOption)`
  height: 3.375rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > *:last-child {
    font-weight: 400;
  }
`;

const Separator = styled.div`
  position: relative;
  height: 1.875rem;
  line-height: 1.875rem;
  padding: 0 0.625rem;
  overflow: hidden;
  > *:first-child {
    position: relative;
    z-index: 1;
    padding-right: 0.9375rem;
    color: ${({ theme }) => colorShade(2, theme.foreground)};
    font-size: 0.78rem;
    font-weight: 500;
  }
  > *:last-child {
    position: absolute;
    top: 50%;
    z-index: 0;
    margin-top: 1px;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => contrastColor(4, theme.background)};
  }
`;

const ChatHeader = ({
  name, nameClick, companyName, detail, detailDate, detailAgent, online, urls,
  options, actions, setQuery, onQueryAction,
}) => {
  const theme = useTheme();
  const texts = useSelector(getTexts);
  const serviceTimeColor = useServiceTimeDate();
  const [showSearcher, setShowSearcher] = useState(false);

  const bodyRef = React.useRef(null);
  React.useEffect(() => {
    if (bodyRef.current) {
      parseEmoji({ node: bodyRef.current });
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: `${HEIGHT}px`,
        padding: '0 0.5rem',
        boxSizing: 'border-box',
        borderBottom: `thin solid ${contrastColor(4, theme.background)}`,
      }}
    >
      <div
        ref={bodyRef}
        style={{
          flexGrow: 1,
          fontSize: '0.90rem',
          overflow: 'hidden',
        }}
      >
        {showSearcher
          ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                }}
              >
                <SearchBar
                  placeholder={texts('Search messages...')}
                  onQuery={setQuery}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.5rem',
                }}
              >
                <IconButton
                  onClick={() => onQueryAction('up')}
                >
                  <i className="fas fa-arrow-up" aria-hidden="true" />
                </IconButton>
                <IconButton
                  onClick={() => onQueryAction('down')}
                >
                  <i className="fas fa-arrow-down" aria-hidden="true" />
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '1.3rem',
                }}
              >
                {online !== undefined ? (
                  <div
                    style={{
                      fontSize: '0.76rem',
                      color: onlineColor(theme, online),
                      marginRight: '0.5rem',
                    }}
                  >
                    <i className="fas fa-circle" />
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0.35rem',
                    overflow: 'hidden',
                  }}
                >
                  <span
                    role="button"
                    style={{
                      fontWeight: '600',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                      outline: 'none',
                    }}
                    onClick={nameClick}
                    onKeyPress={keyPress(nameClick)}
                    tabIndex={0}
                  >
                    {name}
                  </span>
                  {urls ? urls.map((url) => (
                    <IconLink
                      key={url}
                      url={url}
                    />
                  )) : null}
                </div>
                {companyName ? (
                  <Bubble
                    height={15}
                    fontSize="0.80rem"
                    padding={[0, 7, 0, 7]}
                    margin={[0, 6, 0, 0]}
                  >
                    {companyName}
                  </Bubble>
                ) : null}
              </div>
              <div style={{ opacity: 0.8 }}>
                <span>{detail}</span>
                {detailDate ? (
                  <span
                    style={{
                      color: serviceTimeColor(detailDate),
                      fontWeight: 500,
                    }}
                  >
                    &nbsp;
                    {formatRelativeDate(texts, detailDate)}
                  </span>
                ) : null}
                {detailAgent ? (
                  <span
                    style={{ fontWeight: 500 }}
                  >
                    &nbsp;
                    {detailAgent}
                  </span>
                ) : null}
              </div>
            </>
          )}
      </div>
      <IconButton
        onClick={() => {
          setQuery('');
          setShowSearcher(!showSearcher);
        }}
      >
        {showSearcher ? <i className="fas fa-times" /> : <i className="fas fa-search" />}
      </IconButton>
      <Dropdown
        anchorRight
        offsetY={40}
        render={() => (
          <Options>
            {options.map(({ label, onClick }) => (
              <SingleOption
                key={label}
                onClick={onClick}
              >
                {label}
              </SingleOption>
            ))}
            {actions.length ? (
              <>
                <Separator>
                  <span>{texts('Actions')}</span>
                  <span />
                </Separator>
                {actions.map(({ key, label, action }) => (
                  <DoubleOption
                    key={key}
                    onClick={action}
                  >
                    <span>{`/${key}`}</span>
                    <span>{label}</span>
                  </DoubleOption>
                ))}
              </>
            ) : null}
          </Options>
        )}
      >
        <IconButton>
          <i className="fas fa-ellipsis-v" />
        </IconButton>
      </Dropdown>
    </div>
  );
};

ChatHeader.propTypes = {
  name: PropTypes.string.isRequired,
  nameClick: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  detail: PropTypes.string,
  detailDate: PropTypes.number,
  detailAgent: PropTypes.string,
  online: PropTypes.number,
  urls: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    detail: PropTypes.string,
    onClick: PropTypes.func,
  })).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    action: PropTypes.func,
  })),
  setQuery: PropTypes.func,
  onQueryAction: PropTypes.func,
};

ChatHeader.defaultProps = {
  companyName: undefined,
  detail: undefined,
  detailDate: undefined,
  detailAgent: undefined,
  urls: undefined,
  online: undefined,
  actions: [],
  setQuery: () => {},
  onQueryAction: () => {},
};

ChatHeader.HEIGHT = HEIGHT;

export default ChatHeader;
